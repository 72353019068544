import React from 'react';
import { useTranslation } from 'react-i18next';

import CampaignsPlaylists from '@/components/campaigns-page/CampaignsPlaylists';

import ScrollToTopButton from '../components/utility/buttons/ScrollToTopButton';
import UserHeader from '../components/utility/navigation/UserHeader';

const PlaylistPitchesPage = () => {
  const { t } = useTranslation();
  return (
    <div data-testid="playlistng-page" className="page-content">
      <UserHeader title={t('NAVIGATION.PLAYLISTING')} isPlaylisting />
      <ScrollToTopButton />
      <div className="mt10 pos-rel">
        <CampaignsPlaylists />
      </div>
    </div>
  );
};

export default PlaylistPitchesPage;
