import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Tab, Tabs } from '@mui/material';

import ToDoCategory from '@/components/todo-page/ToDoCategory';
import ToDoItem from '@/components/todo-page/ToDoItem';
import { ToDoCategoryList } from '@/constants/ToDoCategoryList';
import useAccountTodos from '@/hooks/todos/useAccountTodos';
import useBreakpoints from '@/hooks/utility/useBreakpoints';
import { TodoAccountDataModel, ToDoCategoryModel, ToDoListModel } from '@/models/Todo';

import ToDoModal from '../components/todo-page/ToDoModal';
import ScrollToTopButton from '../components/utility/buttons/ScrollToTopButton';
import Loading from '../components/utility/Loading';
import UserHeader from '../components/utility/navigation/UserHeader';

const ToDoPage = () => {
  const { t } = useTranslation();
  const { breakpointHit } = useBreakpoints();

  const [chosenToDo, setChosenToDo] = useState<TodoAccountDataModel>();

  const [value, setValue] = useState<number | string>(0);

  const {
    accountTodos,
    partnerTodos,
    adsTodos,
    promotionsTodos,
    artistServicesTodos,
    templateTodos,
    tipsTodos,
    refetchAccountTodos,
    accountTodosIsLoading,
  } = useAccountTodos({
    status: value === 1 ? 'complete' : 'active',
    pageSize: 100,
  });

  const todoItems = useMemo(() => {
    return {
      partners: partnerTodos,
      ads: adsTodos,
      promotion: promotionsTodos,
      'artist-services': artistServicesTodos,
      templates: templateTodos,
      tips: tipsTodos,
    };
  }, [partnerTodos, adsTodos, promotionsTodos, artistServicesTodos, templateTodos, tipsTodos]);

  const toDoCategories = useMemo(() => {
    const categories = ToDoCategoryList.map((item: ToDoListModel) => ({
      title: item.title,
      todos: todoItems[item.value as keyof ToDoCategoryModel] || [],
    }));
    return categories;
  }, [todoItems]);

  useEffect(() => {
    refetchAccountTodos();
  }, [refetchAccountTodos, value]);

  const handleClick = (item: TodoAccountDataModel) => {
    setChosenToDo(item);
  };

  return (
    <div data-testid="to-do-page" className="page-content">
      <UserHeader title="PAGE-TITLES.TO-DO-LIST" />
      <ScrollToTopButton />
      <Box
        sx={{
          width: '100%',
          marginTop: '20px',
        }}
      >
        <Tabs value={value} onChange={(_, newValue) => setValue(newValue)} aria-label="basic tabs example">
          <Tab label={t('COMMON.OPEN')} data-testid="to-do-open" />
          <Tab label={t('COMMON.DONE')} data-testid="to-do-done" />
          {breakpointHit &&
            toDoCategories.map((item, index) => <Tab key={index} label={item && item?.title} value={item.title} />)}
        </Tabs>
      </Box>
      {chosenToDo && (
        <ToDoModal todo={chosenToDo} resetTodo={() => setChosenToDo(undefined)} refreshTodos={refetchAccountTodos} />
      )}
      {accountTodosIsLoading && (
        <div className="centered-loading">
          <Loading />
        </div>
      )}
      {!accountTodosIsLoading &&
        !breakpointHit &&
        value === 0 &&
        toDoCategories?.map((item, index) => {
          if (!item || item.todos.length === 0) return <div key={index}></div>;
          return (
            <div key={index}>
              <ToDoCategory title={item.title} todos={item.todos} setChosenToDo={(todo) => handleClick(todo)} />
            </div>
          );
        })}
      {!accountTodosIsLoading && value === 0 && accountTodos && breakpointHit && (
        <div className="d-flex flex-wrap jc-center gap20 mt8">
          {accountTodos.map((todo, index) => (
            <div key={index}>
              <ToDoItem todo={todo} index={index} setChosenToDo={(todo) => handleClick(todo)} />
            </div>
          ))}
        </div>
      )}
      {!accountTodosIsLoading && value === 1 && accountTodos && (
        <div className="d-flex flex-wrap gap20 mt8">
          {accountTodos.map((todo, index) => (
            <div key={index}>
              <ToDoItem todo={todo} index={index} setChosenToDo={(todo) => handleClick(todo)} />
            </div>
          ))}
        </div>
      )}
      {!accountTodosIsLoading && value !== 0 && value !== 1 && (
        <div className="d-flex flex-wrap jc-center gap20 mt8">
          {toDoCategories.filter((item) => item.title === value)[0].todos.length > 0 ? (
            toDoCategories
              .filter((item) => item.title === value)[0]
              .todos.map((todo, index) => (
                <div key={index}>
                  <ToDoItem todo={todo} index={index} setChosenToDo={(todo) => handleClick(todo)} />
                </div>
              ))
          ) : (
            <div className="text-center card w100p">
              <h5 data-testid="no-pitches-available">No active {value} To Dos</h5>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ToDoPage;
