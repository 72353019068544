import React, { CSSProperties, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { PreviousReleases } from '@/components/release-cycle/PreviousReleases';
import ReleaseCycleDayView from '@/components/release-cycle/ReleaseCycleDayView';
import { ReleaseCycleButton } from '@/components/release-cycle/ReleaseCycleFlow';
import ReleaseCyclePageHeader from '@/components/release-cycle/ReleaseCyclePageHeader';
import ReleaseCycleWeekView from '@/components/release-cycle/ReleaseCycleWeekView';
import ReleaseCycleWeekViewMobile from '@/components/release-cycle/ReleaseCycleWeekViewMobile';
import { ReleaseSettings } from '@/components/release-cycle/ReleaseSettings';
import Loading from '@/components/utility/Loading';
import ButtonComponent from '@/components/utility/microcomponents/Button';
import Card from '@/components/utility/microcomponents/Card';
import CustomIcons from '@/components/utility/microcomponents/CustomIcons';
import { useWalkthrough } from '@/hooks/context/useWalkthrough';
import useReleaseCycles from '@/hooks/release-cycles/useReleaseCycles';
import useBreakpoints from '@/hooks/utility/useBreakpoints';
import { VIEW_STATE } from '@/models/Enums';
import { ReleaseCycleModel } from '@/models/ReleaseCycles';

import UserHeader from '../components/utility/navigation/UserHeader';

const ReleaseCyclePage = () => {
  const { t } = useTranslation();
  const [params, setSearchParams] = useSearchParams();
  const { breakpointHit } = useBreakpoints();
  const { viewState, setViewState } = useWalkthrough();

  const [selectedReleaseCycle, setSelectedReleaseCycle] = useState<ReleaseCycleModel>();
  const [showCompleted, setShowCompleted] = useState<boolean>(true);
  const { releaseCycles, releaseCyclesIsLoading, refetchReleaseCycles } = useReleaseCycles({});
  const { releaseCycles: previousReleaseCycles } = useReleaseCycles({ isCompleted: true });

  useEffect(() => {
    if (!releaseCycles || releaseCycles.length === 0) {
      return;
    }
    if (!params.get('view')) {
      params.set('view', 'day');
      setSearchParams(params);
    }
  }, [params, releaseCycles, setSearchParams]);

  const toggleShowCompleted = () => {
    setShowCompleted(!showCompleted);
  };

  return (
    <div data-testid="promote-page" className="page-content">
      {releaseCyclesIsLoading && (
        <div className="centered-loading">
          <Loading />
        </div>
      )}
      {releaseCycles && releaseCycles.length === 0 && (
        <>
          <UserHeader title="NAVIGATION.RELEASE-CYCLES" />
          <div className="mt10">
            <ReleaseCycleButton entryType="card-one" refetch={refetchReleaseCycles} />
          </div>
          <div className="max-w450">
            {previousReleaseCycles && previousReleaseCycles.length > 0 && (
              <>
                <h5 className="mt20 mb8">{t('RELEASE-CYCLES.PREVIOUS-RELEASES')}</h5>
                <PreviousReleases />
              </>
            )}
          </div>
        </>
      )}
      {releaseCycles && releaseCycles.length > 0 && (
        <>
          <ReleaseCyclePageHeader
            viewCompleted={toggleShowCompleted}
            showCompleted={showCompleted}
            selectedReleaseCycle={setSelectedReleaseCycle}
          />
          <div>
            {params.get('view') === 'day' && selectedReleaseCycle && (
              <ReleaseCycleDayView
                releaseCycle={selectedReleaseCycle}
                viewCompleted={showCompleted}
                toggleCompleted={toggleShowCompleted}
              />
            )}
            {params.get('view') === 'week' && selectedReleaseCycle && (
              <>
                {!breakpointHit && (
                  <ReleaseCycleWeekView releaseCycle={selectedReleaseCycle} viewCompleted={showCompleted} />
                )}
                {breakpointHit && (
                  <ReleaseCycleWeekViewMobile
                    releaseCycle={selectedReleaseCycle}
                    viewCompleted={showCompleted}
                    toggleCompleted={toggleShowCompleted}
                  />
                )}
              </>
            )}
          </div>
          {params.get('view') === 'settings' && selectedReleaseCycle && (
            <ReleaseSettings release={selectedReleaseCycle} />
          )}
          {params.get('view') === 'previous' && (
            <Card className="max-w450 mt20">
              <h5 className="mb8">{t('RELEASE-CYCLES.PREVIOUS-RELEASES')}</h5>
              <PreviousReleases />
            </Card>
          )}
        </>
      )}
      {breakpointHit && params.get('view') !== 'settings' && params.get('view') !== 'previous' && (
        <div
          className={`walkthrough-resume-button-border w100p mt20 mb8 ${viewState && viewState === VIEW_STATE.SKIPPED ? '' : ''}`}
        >
          <div className="walkthrough-resume-button p8">
            <ButtonComponent isCustom className={`w100p jc-center`} onClick={() => setViewState(VIEW_STATE.UNSEEN)}>
              <div className="w100p">
                <div className="d-flex jc-space-between w100p">
                  <p
                    className={`nav-footer-text logout-text pt4 fs-custom`}
                    style={{ '--customFontSize': '14px' } as CSSProperties}
                  >
                    Take a look around
                  </p>
                  <CustomIcons className="logout-icon text-white walkthrough-icon" name="walkthrough" />
                </div>

                <p className="text-faded text-left small">
                  Get started with our walkthrough to learn about the ins-and-outs of using the app.
                </p>
              </div>
            </ButtonComponent>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReleaseCyclePage;
