import React from 'react';

import { PartnerPerkCard } from '@/components/cards/PartnerPerkCard';
import Loading from '@/components/utility/Loading';
import Card from '@/components/utility/microcomponents/Card';
import UserHeader from '@/components/utility/navigation/UserHeader';
import usePartnerPerks from '@/hooks/partner-perks/usePartnerPerks';

const PartnerPerksPage = () => {
  const { partnerPerks, partnerPerksIsLoading } = usePartnerPerks();

  if (partnerPerksIsLoading) {
    return (
      <div className="page-content">
        <div className="centered-loading">
          <Loading />
        </div>
      </div>
    );
  }

  return (
    <div data-testid="partner-perks-page" className="page-content">
      <UserHeader title="NAVIGATION.PARTNER-PERKS" />
      <Card inner className="mt10">
        <h4>What are partner perks?</h4>
        <p className="text-faded mt8">
          Our partners are industry-leading companies offering their services to you. From production to promotion, they
          provide unique benefits designed to support and elevate your music career.
        </p>
      </Card>
      {partnerPerks &&
        partnerPerks.length > 0 &&
        partnerPerks.map((perk, index) => <PartnerPerkCard key={perk.title} perk={perk} index={index} />)}
    </div>
  );
};

export default PartnerPerksPage;
