import React from 'react';
import { useTranslation } from 'react-i18next';

import CampaignsSocialMedia from '@/components/campaigns-page/CampaignsSocialMedia';

import ScrollToTopButton from '../components/utility/buttons/ScrollToTopButton';
import UserHeader from '../components/utility/navigation/UserHeader';

const SocialAdsPage = () => {
  const { t } = useTranslation();
  return (
    <div data-testid="playlistng-page" className="page-content">
      <UserHeader title={t('NAVIGATION.SOCIAL-ADS')} isSocialAds />
      <ScrollToTopButton />
      <div className="mt10 pos-rel">
        <CampaignsSocialMedia />
      </div>
    </div>
  );
};

export default SocialAdsPage;
